<app-preloader></app-preloader>  
<!-- <style type="text/css">
    label{ color: #737b9a; }
</style> -->
    
        <!-- Start Page Title Area -->
        <div class="page-title-area page-title-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Devis en Ligne</h2>

                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Devis en Ligne</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start Checkout Area -->
		<section class="checkout-area ptb-100">
            <div class="container">

                <form>
                    <div class="row">
                        <div class="col-lg-7 col-md-12">
                            <div class="billing-details">
                                <h3 class="title">Détails du Projet</h3>

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Indiquez le type de projet de site internet que vous souhaitez réaliser <span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control">
                                                    <option value=""></option>
                                                    <option value="1">Création de site Internet</option>
                                                    <option value="2">Refonte graphique de site Internet</option>
                                                    <option value="3">Amélioration technique de site Internet</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Indiquez le type de site internet que vous désirez ou que vous possédez<span class="required">*</span></label>
                                            <div class="select-box">
                                                <select class="form-control" #sel (change)="changed1(sel.value)">
                                                    <option value=""></option>
                                                    <option value="1">Site Internet Vitrine</option>
                                                    <option value="2">Application Web (Espace Client, Espace Admin, Dashboard ...)</option>
                                                    <option value="3">Boutique en ligne</option>
                                                    <option value="4">Blog</option>
                                                    <option value="5">Autre site</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Choisissez les supports pour lesquels votre site Internet doit être optimisé<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel2 (change)="changed2(sel2.value)">
                                                    <option value=""></option>
                                                    <option value="1">Site Internet optimisé pour les PC</option>
                                                    <option value="2">Site Internet optimisé pour les Mobiles, les tablettes et les PC</option>
                                                    <option value="3">Site Internet optimisé pour mobiles uniquement</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                   


                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Thèmes<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel3 (change)="changed3(sel3.value)">
                                                    <option value=""></option>
                                                    <option value="1">À partir d'un modèle défini (Template)</option>
                                                    <option value="2">Sur mesure</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Ergonomie<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel4 (change)="changed4(sel4.value)">
                                                    <option value=""></option>
                                                    <option value="1">Simple</option>
                                                    <option value="2">Normale</option>
                                                    <option value="3">Avancé</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Nombre de pages<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel5 (change)="changed5(sel5.value)">
                                                    <option value=""></option>
                                                    <option value="1">1 à 7</option>
                                                    <option value="2">7 à 15</option>
                                                    <option value="3">15 à 30</option>
                                                    <option value="4">30 à 100</option>
                                                    <option value="5">+ 100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                    	<label>Indiquez ici les fonctionnalités de votre site Internet:</label>
                                    	<div class="row mt-1">
                                    		<div class="col-lg-6 col-md-12">
                                    			<div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-1" value="200" #selc1 (change)="changedc1(selc1.value,$event)">
		                                            <label class="form-check-label" for="create-an-1">Actualités</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-2" value="100" #selc2 (change)="changedc2(selc2.value,$event)">
		                                            <label class="form-check-label" for="create-an-2">Recherche</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-3" value="100" #selc3 (change)="changedc3(selc3.value,$event)">
		                                            <label class="form-check-label" for="create-an-3">Formulaire contact</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-4" value="100" #selc4 (change)="changedc4(selc4.value,$event)">
		                                            <label class="form-check-label" for="create-an-4">Galerie Photos</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-5" value="200" #selc5 (change)="changedc5(selc5.value,$event)">
		                                            <label class="form-check-label" for="create-an-5">Multi-langue</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-6" value="100" #selc6 (change)="changedc6(selc6.value,$event)">
		                                            <label class="form-check-label" for="create-an-6">Galerie Vidéos</label>
		                                        </div>
                                    		</div>

                                    		<div class="col-lg-6 col-md-12">
                                    			<div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-7" value="200" #selc7 (change)="changedc7(selc7.value,$event)">
		                                            <label class="form-check-label" for="create-an-7">Commentaires</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-8" value="100" #selc8 (change)="changedc8(selc8.value,$event)">
		                                            <label class="form-check-label" for="create-an-8">Notifications</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-9" value="300" #selc9 (change)="changedc9(selc9.value,$event)">
		                                            <label class="form-check-label" for="create-an-9">Espace Client</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-10" value="400" #selc10 (change)="changedc10(selc10.value,$event)">
		                                            <label class="form-check-label" for="create-an-10">Espace Admin</label>
		                                        </div>

		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-11" value="200" #selc11 (change)="changedc11(selc11.value,$event)">
		                                            <label class="form-check-label" for="create-an-11">Forum</label>
		                                        </div>
		                                        <div class="form-check">
		                                            <input type="checkbox" class="form-check-input" id="create-an-12" value="300" #selc12 (change)="changedc12(selc12.value,$event)">
		                                            <label class="form-check-label" for="create-an-12">Import/Export</label>
		                                        </div>
                                    		</div>
                                    	</div>
                                    </div>


                                    <div class="col-lg-12 col-md-12 mt-3">
                                        <div class="form-group">
										    <label>Création d’un logo<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel6 (change)="changed6(sel6.value)">
                                                    <option value=""></option>
                                                    <option value="1">Oui</option>
                                                    <option value="2">Non</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Indiquez ici les besoins en référencement de votre site Internet<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel7 (change)="changed7(sel7.value)">
                                                    <option value=""></option>
                                                    <option value="1">Je souhaite une prestation de référencement pour mon site Internet</option>
                                                    <option value="2">Je ne souhaite aucune prestation de référencement pour mon site Internet</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Indiquez ici la solution d'hébergement de votre site Internet<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel8 (change)="changed8(sel8.value)">
                                                    <option value=""></option>
                                                    <option value="1">Je souhaite faire héberger mon site Internet</option>
                                                    <option value="2">Je ne souhaite pas faire héberger mon site Internet</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
										    <label>Indiquez ici le délai souhaité pour la création de votre site Internet<span class="required">*</span></label>
										
                                            <div class="select-box">
                                                <select class="form-control" #sel9 (change)="changed9(sel9.value)">
                                                    <option value=""></option>
                                                    <option value="1">15 jours maximum</option>
                                                    <option value="2">1 mois</option>
                                                    <option value="3">2 mois</option>
                                                    <option value="4">3 mois</option>
                                                    <option value="5">+ 6 mois</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                        	<label>Indiquer ici toute information complémentaire que vous jugerez utile à propos de votre projet</label>
                                            <textarea name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                                        </div>
                                    </div>


                                    <!--  -->
                                    <div class="col-lg-12 col-md-12 mt-5 mb-4">
                                    	<h4>Finalisation de votre devis de site Internet :</h4>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Nom <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Prénom <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Société</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-6">
                                        <div class="form-group">
                                            <label>Addresse <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-6">
                                        <div class="form-group">
                                            <label>Ville <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Pays <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Code Postal <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Email  <span class="required">*</span></label>
                                            <input type="email" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label>Téléphone <span class="required">*</span></label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="order-details">
                                <h3 class="title">Estimation de prix</h3>

                                <div class="order-table table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Choix</th>
                                               <!-- <th scope="col">Total</th> -->
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let row of orders;let i = index">
                                                <td class="product-name">
                                                    <span>{{row.name}} </span>
                                                </td>
                                                <!-- <td class="product-total" >
                                                    <span class="subtotal-amount">${{row.item}}</span>
                                                </td> -->
                                            </tr>

                                            <!-- <tr>
                                                <td class="total-price">
                                                    <span>Total</span>
                                                </td>

                                                <td class="product-subtotal">
                                                    <span class="subtotal-amount">${{total}}</span>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>

                                <div class="payment-box payment-box1">
                                    <div class="payment-method">
                                        <p>    
                                            Grâce aux informations que vous venez de saisir, nous pouvons vous adresser par email ou par téléphone des offres et contenus personnalisés :
                                        </p>
                                        <p>
                                            <input type="radio" id="cond1" name="radio-group" checked>
                                            <label for="cond1">Je souhaite recevoir uniquement les propositions liées à mon projet</label>
                                        </p>
                                        <p>
                                            <input type="radio" id="cond2" name="radio-group">
                                            <label for="cond2">Je souhaite recevoir des offres et contenus personnalisés</label>
                                        </p>
                                        <p>
                                            <input type="radio" id="cond3" name="radio-group">
                                            <label for="cond3">Je ne souhaite pas recevoir de devis personnalisé</label>
                                        </p>
                                    </div>
    
                                    <a href="javascript:void(0);" (click)="Continuer()" class="default-btn order-btn">Continuer <span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
		<!-- End Checkout Area -->