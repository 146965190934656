<app-preloader></app-preloader>
        <!-- Start Page Title Area -->
        <div class="page-title-area page-title-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Projets</h2>

                            <ul>
                                <li><a routerLink="/">Accueil</a></li>
                                <li>Projets</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start Projects Area -->
        <section class="projects-area ptb-100">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/bail.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Projet Immobillier</h3>
                                <span class="category">Application web & mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/bail.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/bysur.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Bysur</h3>
                                <span class="category">Site web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/bysur.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/condezza.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Condezza</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/condezza.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/dama.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Jeux des dame</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/dama.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/marketplace.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Marketplace</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/marketplace.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/learning.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Learning</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/learning.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/ventexy.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Ventexy</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/ventexy.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/deluxe.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Deluxe</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/deluxe.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/ineed.jpg" alt="image">

                            <div class="projects-content">
                                <h3>ineed</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/ineed.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/condezza.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Assumarisk</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/condezza.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/bysur.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Bysur</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/bysur.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/foir.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Foire</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/foir.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/quiz.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Quiz</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/quiz.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/hairify.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Hairify</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/hairify.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/decor.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Decor</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/decor.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/quran.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Quran</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/quran.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/deluxe.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Deluxe</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/deluxe.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/tafsir.jpg" alt="image">

                            <div class="projects-content">
                                <h3>tafsir</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/tafsir.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/list/tasbih.jpg" alt="image">

                            <div class="projects-content">
                                <h3>tasbih</h3>
                                <span class="category">Application mobile</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/list/tasbih.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-projects-box">
                            <img src="assets/img/projects-image/sites/bysur-questionnaire.jpg" alt="image">

                            <div class="projects-content">
                                <h3>Bysur questionnaire</h3>
                                <span class="category">Application web</span>
                            </div>

                            <div class="plus-icon">
                                <a href="assets/img/projects-image/sites/bysur-questionnaire.jpg" class="popup-btn">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    


                </div>
            </div>
        </section>
        <!-- End Projects Area -->


