<app-preloader></app-preloader>
 <!-- Start Page Title Area -->
        <div class="page-title-area page-title-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Compétences</h2>

                            <ul>
                                <li><a routerLink="/">Accueil</a></li>
                                <li>Compétences</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Page Title Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/features-image/6.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <span class="sub-title">Compétences</span>
                            <h2>Avoir un site web , c'est indispensable !</h2>
                            <p>Quelle que soit votre problématique métier, nous accompagnons les entreprises dans l'étude et la réalisation d'applications web dédiées à leur activité, en utilisant les meilleures solutions Open Source.</p>

                            <ul class="features-list">
                                <li><span><i class="fas fa-check"></i> Responsive</span></li>
                                <li><span><i class="fas fa-check"></i> Sur mesure</span></li>
                                <li><span><i class="fas fa-check"></i> Administrable</span></li>
                                <li><span><i class="fas fa-check"></i> UX/UI design</span></li>
                                <li><span><i class="fas fa-check"></i> 24/7 Maintenance</span></li>
                                <li><span><i class="fas fa-check"></i> SEO friendly</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
        </section>
        <!-- End About Area -->

       <!-- Start Features Area -->
        <section class="features-area ptb-100 pt-0">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Compétences</span>
                    <h2>Compétences techniques</h2>
                </div>

                <div class="tab features-list-tab">
                    <ul class="tabs">
                        <li><a href="#" class="bg-fa7070">
                            <i class="fa fa-desktop icon-updatet"></i>
                            <span>Sites <br>web</span>
                        </a></li>

                         <li><a href="#" class="bg-f78acb">
                            <i class="flaticon-data"></i>
                            <span>Applications <br>web</span>
                        </a></li>

                        <li><a href="#" class="bg-00aeff">
                            <i class="flaticon-architecture"></i>
                            <span>Design <br>graphique</span>
                        </a></li>
                        
                        <li><a href="#" class="bg-c679e3">
                            <i class="fa fa-mobile-alt icon-updatet"></i>
                            <span>Applications mobiles</span>
                        </a></li>
                        
                        <li><a href="#" class="bg-eb6b3d">
                            <i class="flaticon-analytics"></i>
                            <span>Solution ERP Odoo</span>
                        </a></li>
                        
                        <li><a href="#">
                            <i class="flaticon-analysis"></i>
                            <span>Référencement Naturel</span>
                        </a></li>
                        
                       
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Sites web</span>
                                        <h2>Languages et frameworks</h2>
                                        <p>Nous disposons du savoir-faire et de l'expérience indispensables à la conception et à la création de sites web responsives, répondant à l'ensemble des normes actuelles.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Angular js | React js</span></li>
                                            <li><span><i class="flaticon-tick"></i> Node js | Laravel</span></li>
                                            <li><span><i class="flaticon-tick"></i> Wordpress</span></li>
                                            <li><span><i class="flaticon-tick"></i> Mongo db | mysql</span></li>
                                            <li><span><i class="flaticon-tick"></i> Sass | Less</span></li>
                                            <li><span><i class="flaticon-tick"></i> PHP | JS | HTML</span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/1.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/3.png" alt="image">
                                    </div>
                                </div>

                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Applications web</span>
                                        <h2>Languages et frameworks</h2>
                                        <p>Nous vous accompagnons dans la définition de votre application web dès sa conception et nous vous aidons à réaliser le projet adapté à vos besoins et à ceux de vos clients.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Angular js | React js</span></li>
                                            <li><span><i class="flaticon-tick"></i> Node js | Laravel</span></li>
                                            <li><span><i class="flaticon-tick"></i> Wordpress</span></li>
                                            <li><span><i class="flaticon-tick"></i> Mongo db | mysql</span></li>
                                            <li><span><i class="flaticon-tick"></i> Sass | Less</span></li>
                                            <li><span><i class="flaticon-tick"></i> PHP | JS | HTML</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/2.png" alt="image">
                                    </div>
                                </div>

                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Design graphique</span>
                                        <h2>Logiciels et outils utilisés</h2>
                                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Adobe Photoshop</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe After Effects</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe premiere</span></li>
                                            <li><span><i class="flaticon-tick"></i> Illustrator</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe XD</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Applications mobiles</span>
                                        <h2>Quelles application choisir ?</h2>
                                        <p>Une fois l’enjeu déterminé et vos objectifs fixés, nous vous proposons de créer votre plateforme mobile en adéquation avec le profil d’utilisation de vos clients :</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Applications Natives </span></li>
                                            <li><span><i class="flaticon-tick"></i> Applications web</span></li>
                                            <li><span><i class="flaticon-tick"></i> Application hybride</span></li>
                                            <li><span><i class="flaticon-tick"></i> Design optimale</span></li>
                                            <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/6.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/4.png" alt="image">
                                    </div>
                                </div>
                                
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Solutions ERP Odoo</span>
                                        <h2>Languages et Utilisation</h2>
                                        <p>Odoo est un logiciel d'entreprise qui est fait pour améliorer la productivité et la rentabilité grâce à l'intégration des données.Il met en relation, améliore et gère les processus dans les domaines des ventes, de la finance, de la logistique, de la gestion de projets , la production, les services, la gestion de la relation client, etc..</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Python | XML</span></li>
                                            <li><span><i class="flaticon-tick"></i> Apps Marketing</span></li>
                                            <li><span><i class="flaticon-tick"></i> Apps Ventes</span></li>
                                            <li><span><i class="flaticon-tick"></i> Applications financière</span></li>
                                            <li><span><i class="flaticon-tick"></i> Ressources humaines Apps</span></li>
                                            <li><span><i class="flaticon-tick"></i> Communication</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Référencement</span>
                                        <h2>Référencement Naturel</h2>
                                        <p>Le Référencement naturel de site web permet de fortement améliorer le positonnement de son site sur les moteurs de recherche par une optimisation maximale.Notamment un travail en continu sur la qualité du site et sur ses liens permettrons d’atteindre progressivement l’objectif qui est LA PAGE 1 DE GOOGLE.</p>

                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/5.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
        </section>
        <!-- End Features Area -->

>

        <!-- Start Subscribe Area -->
        <section class="subscribe-area bg-F4F7FC">
            <div class="container-fluid p-0">
                <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="subscribe-content">
                        <span class="sub-title">UN VRAI SAVOIR-FAIRE AUTOUR DES NOUVELLES TECHNOLOGIES!</span>
                        <h2>Demandez un devis rapidement !</h2>

                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                            <button type="submit">Contact</button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
        </section>
        <!-- End Subscribe Area -->

        


        <!-- Start Partner Area -->
        <section class="partner-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Compétences</span>
                    <h2>Résumé de nos compétences techniques en images</h2>
                </div>

                <div class="row align-items-center">
                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a1.png" alt="image">
                            <img src="assets/img/partner-image/a1.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a2.png" alt="image">
                            <img src="assets/img/partner-image/a2.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a3.png" alt="image">
                            <img src="assets/img/partner-image/a3.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a4.png" alt="image">
                            <img src="assets/img/partner-image/a4.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a5.png" alt="image">
                            <img src="assets/img/partner-image/a5.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a10.png" alt="image">
                            <img src="assets/img/partner-image/a10.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a6.png" alt="image">
                            <img src="assets/img/partner-image/a6.png" alt="image">
                        </a>
                    </div>

                    

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a7.png" alt="image">
                            <img src="assets/img/partner-image/a7.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a8.png" alt="image">
                            <img src="assets/img/partner-image/a8.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a9.png" alt="image">
                            <img src="assets/img/partner-image/a9.png" alt="image">
                        </a>
                    </div>

                    

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a11.png" alt="image">
                            <img src="assets/img/partner-image/a11.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a12.png" alt="image">
                            <img src="assets/img/partner-image/a12.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a13.png" alt="image">
                            <img src="assets/img/partner-image/a13.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a14.png" alt="image">
                            <img src="assets/img/partner-image/a14.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a15.png" alt="image">
                            <img src="assets/img/partner-image/a15.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
        </section>
        <!-- End Partner Area -->

