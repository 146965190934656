import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  links: string[]  =['/','/#services','/contact','/projects','/services','/skills','/devis'];
  title = 'portfolio';
  constructor(public router:Router) {  }
}
