<app-preloader></app-preloader>  

    

        <!-- Start Main Banner Area -->
        <div class="banner-section" *ngIf="random == 1 ">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="banner-content">
                                    <span class="sub-title">Bienvenue.</span>
                                    <h1 class="graas" style="text-transform: uppercase;">on façonne à vos mesures</h1>
                                    <p>L’innovation technologique et la qualité de nos produits accompagnent tous nos clients. Digi-Dev s’emploie à développer des produits personnalisés et adaptés à vos propres besoins.</p>

                                    <div class="btn-box mt-20">
                                        <a routerLink="/devis" class="default-btn">Demander un Devis <span></span></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="banner-image">
                                    <img src="assets/img/rocket.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                                    <img src="assets/img/rocket-shape.png" class="wow fadeIn" data-wow-delay="1s" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="cloud-shape"><img src="assets/img/cloud-shape.png" alt="image"></div>
            <div class="cloud-icon1"><img src="assets/img/cloud1.png" alt="image"></div>
            <div class="cloud-icon2"><img src="assets/img/cloud2.png" alt="image"></div>
            <div class="cloud-icon3"><img src="assets/img/cloud3.png" alt="image"></div>
        </div>
        <!-- End Main Banner Area -->

        <!-- Start Main Banner Area -->
        <div class="hero-banner banner-bg2" *ngIf="random == 2 ">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="hero-main-banner-content">
                                    <span class="sub-title">Développement sur mesure</span>
                                    <h1>Agence Web <br> Digi-Dev</h1>
                                    <p>Développement de solutions web adaptées aux besoins de nos clients pour optimiser leurs performances et accompagner leur croissance.</p>

                                    <div class="btn-box">
                                        <a routerLink="/devis" class="default-btn">Demander un Devis <span></span></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="hero-main-banner-image">
                                    <img src="assets/img/banner-image/man.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="man">
                                    <img src="assets/img/banner-image/code.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="code">
                                    <img src="assets/img/banner-image/carpet.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="carpet">
                                    <img src="assets/img/banner-image/bin.png" class="wow zoomIn" data-wow-delay="0.6s" alt="bin">
                                    <img src="assets/img/banner-image/book.png" class="wow bounceIn" data-wow-delay="0.6s" alt="book">
                                    <img src="assets/img/banner-image/desktop.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="desktop">
                                    <img src="assets/img/banner-image/dot.png" class="wow zoomIn" data-wow-delay="0.6s" alt="dot">
                                    <img src="assets/img/banner-image/flower-top-big.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="flower-top-big">
                                    <img src="assets/img/banner-image/flower-top.png" class="wow rotateIn" data-wow-delay="0.6s" alt="flower-top">
                                    <img src="assets/img/banner-image/keyboard.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="keyboard">
                                    <img src="assets/img/banner-image/pen.png" class="wow zoomIn" data-wow-delay="0.6s" alt="pen">
                                    <img src="assets/img/banner-image/table.png" class="wow zoomIn" data-wow-delay="0.6s" alt="table">
                                    <img src="assets/img/banner-image/tea-cup.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="tea-cup">
                                    <img src="assets/img/banner-image/headphone.png" class="wow rollIn" data-wow-delay="0.6s" alt="headphone">

                                    <img src="assets/img/banner-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img1"><img src="assets/img/shape/1.png" class="wow fadeInUp" data-wow-delay=".9s" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Main Banner Area -->

        <!-- Start Main Banner Area -->
        <div class="main-banner" *ngIf="random == 4 ">
            <div class="container">
                <div class="main-banner-content">
                    <span class="sub-title">Bienvenue.</span>
                    <h2 class="graas" style="text-transform: uppercase;">Idée, inspiration, pensée, illusion, imagination</h2>
                    <p> "La valeur d'une idée n'a absolument rien à voir avec la sincérité de l'homme qui l'exprime."<br>
                    "On n'est créatif qu'à plusieurs"</p>

                    <div class="btn-box">
                        <a routerLink="/devis" class="default-btn">Demander un Devis <span></span></a>
                    </div>
                </div>

                <div class="main-banner-image">
                    <img src="assets/img/banner-image/1-1.png" alt="image">
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Main Banner Area -->

        <!-- Start Main Banner Area -->
        <div class="hero-banner banner-bg1" *ngIf="random == 3 ">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="hero-banner-content">
                                    <span class="sub-title">Développement sur mesure</span>
                                    <h1>Création de site Web, E-commerce</h1>
                                    <h1>Solution ERP Odoo</h1>
                                    <p>Vous souhaitez confirmer votre présence digitale sur le Web ? <br> Digi-Dev vous propose un site Web dynamique fonctionnel adapté aux smartphones et aux tablettes</p>

                                    <div class="btn-box">
                                        <a routerLink="/devis" class="default-btn">Demander un Devis <span></span></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="hero-banner-image">
                                    <img src="assets/img/banner-image/main.png" alt="image">
                                    <img src="assets/img/banner-image/1.png" class="wow fadeInDown" data-wow-delay=".9s" alt="image">
                                    <img src="assets/img/banner-image/2.png" class="wow fadeInDown" data-wow-delay=".6s" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img1"><img src="assets/img/shape/1.png" class="wow fadeInUp" data-wow-delay=".9s" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Main Banner Area -->

        <!-- Start Featured Services Area -->
        <section class="featured-services-area ptb-100 pt-0 mt-80" id="services">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Nous offrons</span>
                    <h2>Services</h2>
                    <p>Nous efforçons constamment de maintenir et de mettre à jour nos connaissances, notre expertise et nos offres de services afin de mieux répondre aux besoins de nos clients.</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-featured-box">
                            <div class="icon">
                                <i class="fa fa-desktop"></i>
                            </div>

                            <h3>Sites & applications web</h3>
                            <p>Nous disposons du savoir-faire et de l'expérience indispensables à la conception et à la création de sites web intelligents, répondant à l'ensemble des normes actuelles.</p>

                            <a routerLink="/services" class="default-btn">Voir plus <span></span></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-featured-box">
                            <div class="icon color-facd60">
                                <i class="fa fa-mobile-alt" aria-hidden="true"></i>

                            </div>

                            <h3>Applications mobiles</h3>
                            <p>Conception et développement Android, iOS et Windows Phone. Pour votre première application mobile, choisissez une équipe qui sera attentive à toutes vos exigences.</p>

                            <a routerLink="/services" class="default-btn">Voir plus <span></span></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="single-featured-box">
                            <div class="icon color-1ac0c6">
                                <!-- <i class="fa fa-database"></i> -->
                                <i class="flaticon-analytics"></i>
                            </div>

                            <h3>Solution ERP Odoo</h3>
                            <p>Le logiciel ERP propose de nombreux avantages aux entreprises : base de données centralisée, outil commun pour l’ensemble des équipes, gain de productivité… </p>

                            <a routerLink="/services" class="default-btn">Voir plus <span></span></a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!-- End Featured Services Area -->


        <!-- Start Features Area -->
        <section class="features-area ptb-100 pt-0">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Compétences</span>
                    <h2>Compétences techniques</h2>
                </div>

                <div class="tab features-list-tab">
                    <ul class="tabs">
                        <li><a href="#" class="bg-fa7070">
                            <i class="fa fa-desktop icon-updatet"></i>
                            <span>Sites <br>web</span>
                        </a></li>

                         <li><a href="#" class="bg-f78acb">
                            <i class="flaticon-data"></i>
                            <span>Applications <br>web</span>
                        </a></li>

                        <li><a href="#" class="bg-00aeff">
                            <i class="flaticon-architecture"></i>
                            <span>Design <br>graphique</span>
                        </a></li>
                        
                        <li><a href="#" class="bg-c679e3">
                            <i class="fa fa-mobile-alt icon-updatet"></i>
                            <span>Applications mobiles</span>
                        </a></li>
                        
                        <li><a href="#" class="bg-eb6b3d">
                            <i class="flaticon-analytics"></i>
                            <span>Solution ERP Odoo</span>
                        </a></li>
                        
                        <li><a href="#">
                            <i class="flaticon-analysis"></i>
                            <span>Référencement Naturel</span>
                        </a></li>
                        
                       
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Sites web</span>
                                        <h2>Languages et frameworks</h2>
                                        <p>Nous disposons du savoir-faire et de l'expérience indispensables à la conception et à la création de sites web responsives, répondant à l'ensemble des normes actuelles.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Angular js | React js</span></li>
                                            <li><span><i class="flaticon-tick"></i> Node js | Laravel</span></li>
                                            <li><span><i class="flaticon-tick"></i> Wordpress</span></li>
                                            <li><span><i class="flaticon-tick"></i> Mongo db | mysql</span></li>
                                            <li><span><i class="flaticon-tick"></i> Sass | Less</span></li>
                                            <li><span><i class="flaticon-tick"></i> PHP | JS | HTML</span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/1.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/3.png" alt="image">
                                    </div>
                                </div>

                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Applications web</span>
                                        <h2>Languages et frameworks</h2>
                                        <p>Nous vous accompagnons dans la définition de votre application web dès sa conception et nous vous aidons à réaliser le projet adapté à vos besoins et à ceux de vos clients.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Angular js | React js</span></li>
                                            <li><span><i class="flaticon-tick"></i> Node js | Laravel</span></li>
                                            <li><span><i class="flaticon-tick"></i> Wordpress</span></li>
                                            <li><span><i class="flaticon-tick"></i> Mongo db | mysql</span></li>
                                            <li><span><i class="flaticon-tick"></i> Sass | Less</span></li>
                                            <li><span><i class="flaticon-tick"></i> PHP | JS | HTML</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/2.png" alt="image">
                                    </div>
                                </div>

                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Design graphique</span>
                                        <h2>Logiciels et outils utilisés</h2>
                                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Adobe Photoshop</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe After Effects</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe premiere</span></li>
                                            <li><span><i class="flaticon-tick"></i> Illustrator</span></li>
                                            <li><span><i class="flaticon-tick"></i> Adobe XD</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Applications mobiles</span>
                                        <h2>Quelles application choisir ?</h2>
                                        <p>Une fois l’enjeu déterminé et vos objectifs fixés, nous vous proposons de créer votre plateforme mobile en adéquation avec le profil d’utilisation de vos clients :</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Applications Natives </span></li>
                                            <li><span><i class="flaticon-tick"></i> Applications web</span></li>
                                            <li><span><i class="flaticon-tick"></i> Application hybride</span></li>
                                            <li><span><i class="flaticon-tick"></i> Design optimale</span></li>
                                            <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/6.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/4.png" alt="image">
                                    </div>
                                </div>
                                
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Solutions ERP Odoo</span>
                                        <h2>Languages et Utilisation</h2>
                                        <p>Odoo est un logiciel d'entreprise qui est fait pour améliorer la productivité et la rentabilité grâce à l'intégration des données.Il met en relation, améliore et gère les processus dans les domaines des ventes, de la finance, de la logistique, de la gestion de projets , la production, les services, la gestion de la relation client, etc..</p>

                                        <ul class="features-list">
                                            <li><span><i class="flaticon-tick"></i> Python | XML</span></li>
                                            <li><span><i class="flaticon-tick"></i> Apps Marketing</span></li>
                                            <li><span><i class="flaticon-tick"></i> Apps Ventes</span></li>
                                            <li><span><i class="flaticon-tick"></i> Applications financière</span></li>
                                            <li><span><i class="flaticon-tick"></i> Ressources humaines Apps</span></li>
                                            <li><span><i class="flaticon-tick"></i> Communication</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item">
                            <div class="features-overview">
                                <div class="overview-content">
                                    <div class="content">
                                        <span class="sub-title">Référencement</span>
                                        <h2>Référencement Naturel</h2>
                                        <p>Le Référencement naturel de site web permet de fortement améliorer le positonnement de son site sur les moteurs de recherche par une optimisation maximale.Notamment un travail en continu sur la qualité du site et sur ses liens permettrons d’atteindre progressivement l’objectif qui est LA PAGE 1 DE GOOGLE.</p>
                                    </div>
                                </div>

                                <div class="overview-image">
                                    <div class="image">
                                        <img src="assets/img/features-image/5.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
        </section>
        <!-- End Features Area -->


        


        <!-- Start Projects Area -->
        <section class="projects-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Projets</span>
                    <h2>Un excellent portfolio permet au travail de parler de lui-même. <br>
                    Voici quelques-uns des meilleurs.</h2>
                </div>
            </div>

            <div class="container-fluid">
                <div class="projects-slides owl-carousel owl-theme">

                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/list/bysur.jpg" alt="image">

                        <div class="projects-content">
                            <h3>Bysur</h3>
                            <span class="category">Application mobile</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/bysur.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/sites/ventexy.jpg" alt="image">

                        <div class="projects-content">
                            <h3><a href="ventexy.com">Ventexy </a></h3>
                            <span class="category">Application web - Logciel de facturation</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/sites/ventexy.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/sites/bysur.jpg" alt="image">

                        <div class="projects-content">
                            <h3>Bysur</h3>
                            <span class="category">Application web</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/sites/bysur.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/sites/bail.jpg" alt="image">

                        <div class="projects-content">
                            <h3>Bails</h3>
                            <span class="category">Application web</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/sites/bail.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    
                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/list/ineed.jpg" alt="image">

                        <div class="projects-content">
                            <h3>Ineed</h3>
                            <span class="category">Application mobile</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/list/ineed.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    

                    <div class="single-projects-box">
                        <img src="assets/img/projects-image/list/condezza.jpg" alt="image">

                        <div class="projects-content">
                            <h3>Vondezza</h3>
                            <span class="category">Application mobile</span>
                        </div>

                        <div class="plus-icon">
                            <a href="assets/img/projects-image/list/condezza.jpg" class="popup-btn">
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Projects Area -->




        <!-- Start Solution Area -->
        <section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="solution-content">
                            <span class="sub-title">REGARDEZ NOTRE VIDEOS</span>
                            <h2>Ce vidéo permet au travail de parler de lui-même. </h2>
                            <p>Application  web permet gérer les chéques de l'étape de scan jusqu'a l'étade d'encaissement , avec l'option "scanner et recupérer CMC7" .</p>

                            <a href="/contact" class="default-btn">Contactez Nous <span></span></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="solution-video">
                            <a href="https://www.youtube.com/watch?v=BwgV6o_SX1c" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Solution Area -->

   
        <section class="partner-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a1.png" alt="image">
                            <img src="assets/img/partner-image/a1.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a2.png" alt="image">
                            <img src="assets/img/partner-image/a2.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a3.png" alt="image">
                            <img src="assets/img/partner-image/a3.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a4.png" alt="image">
                            <img src="assets/img/partner-image/a4.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a5.png" alt="image">
                            <img src="assets/img/partner-image/a5.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a10.png" alt="image">
                            <img src="assets/img/partner-image/a10.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a6.png" alt="image">
                            <img src="assets/img/partner-image/a6.png" alt="image">
                        </a>
                    </div>

                    

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a7.png" alt="image">
                            <img src="assets/img/partner-image/a7.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a8.png" alt="image">
                            <img src="assets/img/partner-image/a8.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="javascript:void(0);">
                            <img src="assets/img/partner-image/a9.png" alt="image">
                            <img src="assets/img/partner-image/a9.png" alt="image">
                        </a>
                    </div>

                    
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
        </section>



        <!-- Start Subscribe Area -->
        <section class="subscribe-area bg-F4F7FC" >
            <div class="container-fluid p-0">
                <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="subscribe-content">
                        <span class="sub-title">UN VRAI SAVOIR-FAIRE AUTOUR DES NOUVELLES TECHNOLOGIES!</span>
                        <h2>Demandez un devis rapidement !</h2>

                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                            <button type="submit">Contact</button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
        </section>
        <!-- End Subscribe Area -->

        <!-- Start About Area -->
        <section class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/features-image/2.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <span class="sub-title">Compétences</span>
                            <h2>Avoir un site web , c'est indispensable !</h2>
                            <p>Quelle que soit votre problématique métier, nous accompagnons les entreprises dans l'étude et la réalisation d'applications web dédiées à leur activité, en utilisant les meilleures solutions Open Source.</p>

                            <ul class="features-list">
                                <li><span><i class="fas fa-check"></i> Responsive</span></li>
                                <li><span><i class="fas fa-check"></i> Sur mesure</span></li>
                                <li><span><i class="fas fa-check"></i> Administrable</span></li>
                                <li><span><i class="fas fa-check"></i> UX/UI design</span></li>
                                <li><span><i class="fas fa-check"></i> 24/7 Maintenance</span></li>
                                <li><span><i class="fas fa-check"></i> e-commerce</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
        </section>
