import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  random2 : number;
  constructor() { }

  ngOnInit(): void {
  	this.random2 = Math.floor(Math.random() * 2) + 1 ;
  }

}
