import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { Error404Component } from './error404/error404.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsComponent } from './projects/projects.component';
import { HeaderComponent } from './header/header.component';
import { ServicesComponent } from './services/services.component';
import { SkillsComponent } from './skills/skills.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { DynamicFileAdminServiceService } from './dynamic-file-service.service';
import { DevisComponent } from './devis/devis.component';
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    Error404Component,
    FooterComponent,
    ContactComponent,
    ProjectsComponent,
    HeaderComponent,
    ServicesComponent,
    SkillsComponent,
    PreloaderComponent,
    DevisComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([
      { path: '', component: IndexComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'projects', component: ProjectsComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'skills', component: SkillsComponent },
      { path: 'devis', component: DevisComponent },
      { path: '**', component: Error404Component },
    ])
  ],
  providers: [DynamicFileAdminServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
