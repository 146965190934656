import { Component, OnInit } from '@angular/core';
import { DynamicFileAdminServiceService } from 'src/app/dynamic-file-service.service';
@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css']
})
export class PreloaderComponent implements OnInit {

  constructor(private dynamicScriptLoader: DynamicFileAdminServiceService) { }

  ngOnInit() {
    
     this.loadScripts();
  }

  // js
  private loadScripts() {
    this.dynamicScriptLoader.load('c1').then(data => {
    }).catch(error => console.log(error));
  }

}
