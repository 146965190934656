<app-preloader></app-preloader>



       <!-- Start Page Title Area -->
        <div class="page-title-area page-title-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Services</h2>

                            <ul>
                                <li><a routerLink="/">Home</a></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
            <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
            <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
            <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
            <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
            <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
        </div>
        <!-- End Page Title Area -->

        
        <!-- Start Services Area -->
        <section class="services-area bg-F4F7FC">
            <div class="container">
                <!-- <div class="section-title">
                    <span class="sub-title">Nous offrons</span>
                    <h2>Services</h2>
                </div> -->
                
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="fa fa-desktop"></i>
                            </div>

                            <h3>Sites web</h3>

                            <p>Nous disposons du savoir-faire et de l'expérience indispensables à la conception et à la création de sites web intelligents, répondant à l'ensemble des normes actuelles.</p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon  bg-00aeff">
                                <i class="flaticon-data"></i>
                            </div>

                            <h3>Applications web</h3>

                            <p>Nous vous accompagnons dans la définition de votre application web dès sa conception et nous vous aidons à réaliser le projet adapté à vos besoins et à ceux de vos clients.</p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon bg-eb6b3d">
                                <i class="flaticon-architecture"></i>
                            </div>

                            <h3>Design graphique</h3>

                            <p>Nous intervenons non seulement dans la création des chartes graphiques, relooking, création de logo, Nos spécialistes assurent des créations graphiques à fort impact visuel.</p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon bg-f78acb">
                                <i class="fa fa-mobile-alt"></i>
                            </div>

                            <h3>Applications mobiles</h3>

                            <p>Conception et développement Android, iOS et Windows Phone. Pour votre première application mobile, choisissez une équipe qui sera attentive à toutes vos exigences.</p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon bg-cdf1d8">
                                <i class="flaticon-analytics"></i>
                            </div>

                            <h3>Solution ERP Odoo</h3>

                            <p>Le logiciel ERP propose de nombreux avantages aux entreprises : base de données centralisée, outil commun pour l’ensemble des équipes, gain de productivité… </p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-services-box">
                            <div class="icon bg-c679e3">
                                <i class="flaticon-analysis"></i>
                            </div>

                            <h3>Référencement Naturel</h3>

                            <p>Nous disposons d’une expertise en optimisation et référencement de sites web.En ce moment pour toute création de site web, nous vous offrons le référencement !</p>
                            <a routerLink="/devis" class="read-more-btn">Devis <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>

                    

                </div>
            </div>
        </section>
        <!-- End Services Area -->





         <!-- Start Overview Area -->
        <section class="overview-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Services</span>
                    <h2>Notre processus de travail</h2>
                    <p>Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.</p>
                </div>

                <div class="overview-box">
                    <div class="overview-content">
                        <div class="content">
                            <h2>Notre processus</h2>
                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Accompagnement</h4>
                                <p>Notre première rencontre vous offre une réflexion professionnelle sur votre projet. Nous établissons ensemble une liste priorisée des fonctionnalités de votre produit.</p>
                            </div>

                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Devis Détaillé</h4>
                                <p>Un devis gratuit vous est envoyé sous quelques jours et vous permet d'estimer les charges et le temps de développement nécessaires à votre projet</p>
                            </div>
                        </div>
                    </div>

                    <div class="overview-image">
                        <div class="image">
                            <img src="assets/img/about-img1.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="overview-box">
                    <div class="overview-image">
                        <div class="image">
                            <img src="assets/img/why-choose-img1.png" alt="image">
                        </div>
                    </div>
                    
                    <div class="overview-content">
                        <div class="content right-content">
                            <h2>Notre processus</h2>
                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Développement</h4>
                                <p>Chaque étape de développement dure de 1 à 3 semaines. Elle comprend les fonctionnalités que vous avez ciblées comme prioritaires et promet un livrable fonctionnel à chaque fin d'étape.</p>
                            </div>

                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Facturation</h4>
                                <p>Un devis gratuit vous est envoyé sous quelques jours et vous permet d'estimer les charges et le temps de développement nécessaires à votre projet</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="overview-box">
                    <div class="overview-content">
                        <div class="content">
                            <h2>Notre processus</h2>
                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Produit fini</h4>
                                <p>Vous profitez d'un produit fonctionnel à la fin de chaque étape.</p>
                            </div>
                            <div class="features-text">
                                <h4><i class="flaticon-tick"></i> Maintenance</h4>
                                <p>Notre maintenance active vous assure le bon fonctionnement de votre produit. Ainsi, votre projet bénéficie d'un accompagnement complet.</p>
                            </div>
                        </div>
                    </div>

                    <div class="overview-image">
                        <div class="image">
                            <img src="assets/img/features-image/2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Overview Area -->



