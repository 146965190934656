<!-- Start Footer Area -->
        <section class="footer-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-footer-widget">
                            <h3>Contact Info</h3>

                            <ul class="footer-contact-info">
                                <li>
                                    <i class="flaticon-phone-call"></i>
                                    <span>Mon to Fri : 10:00AM - 06:00PM</span>
                                    <a href="tel:+33756887505">+33 7 56 88 75 05</a>
                                </li>
                                <li>
                                    <i class="flaticon-email"></i>
                                    <span>Avez-vous une Question? </span>
                                    <a href="mailto:faresmanaa1234@gmail.com">contact@digi-dev.net</a>
                                </li>
                                <li>
                                    <i class="flaticon-social-media"></i>
                                    <span>RÉSEAUX SOCIAUX</span>

                                    <ul class="social">
                                        <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.facebook.com/digidev.net" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                        <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-footer-widget pl-5">
                            <h3>Liens rapides</h3>

                            <ul class="footer-quick-links">
                                <li><a routerLink="/">Accueil</a></li>
                                <li><a routerLink="/Services">Services</a></li>
                                <li><a routerLink="/skills">Skills</a></li>
                                <li><a routerLink="/projects">Projets</a></li>
                                <li><a routerLink="/contact">Contact</a></li>
                                <li><a routerLink="/devis">Devis</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="single-footer-widget pl-5">
                            <h3>Projets</h3>

                            <ul class="footer-instagram-post">
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a1.jpg" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a2.png" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a3.png" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a4.jpg" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a5.png" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a6.jpg" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a7.png" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a8.jpg" alt="image">
                                </a></li>
                                <li><a href="javascript:void(0);">
                                    <img src="assets/img/instagram-image/a9.png" alt="image">
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="copyright-area">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6 col-md-6">
                            <p><i class="flaticon-copyright"></i> Copyright 2019 - {{now}} <a routerLink="/" >Digi-Dev</a>. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Footer Area -->