import { Component, OnInit } from '@angular/core';
import { DynamicFileAdminServiceService } from 'src/app/dynamic-file-service.service';

@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html',
  styleUrls: ['./devis.component.css']
})
export class DevisComponent implements OnInit {
  value:string;
  total:number=0;
  orders:Array<{id:number, name: string, item: number}> = []; 

  constructor(private dynamicScriptLoader: DynamicFileAdminServiceService) { }

  ngOnInit(): void {
     // this.orders.push({name:"Total" , item:0});
  }


    // js
  private loadScripts() {
    this.dynamicScriptLoader.load('c1').then(data => {
    }).catch(error => console.log(error));
  }

  changed1(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="Site Internet Vitrine"; tot= 300;}
    if(value == "2"){name="Application Web"; tot= 500;}
    if(value == "3"){name="Boutique en ligne"; tot= 400;}
    if(value == "4"){name="Blog"; tot= 200;}
    if(value == "5"){name="Autre site"; tot= 400;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(1);
    if(removeIndex != -1) this.orders.splice(removeIndex, 1);
    this.orders.push({id:1,name:name , item:tot});
    
    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(1);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
    
    
  }

  changed2(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="Site Internet optimisé pour les PC"; tot= 100;}
    if(value == "2"){name="Site Internet optimisé pour les Mobiles, les tablettes et les PC"; tot= 200;}
    if(value == "3"){name="Site Internet optimisé pour mobiles uniquement"; tot= 150;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(2);
    if(removeIndex != -1) this.orders.splice(removeIndex, 1);
    this.orders.push({id:2,name:name , item:tot});

    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(2);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
  }
  changed3(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="À partir d'un modèle défini (Template)"; tot= 150;}
    if(value == "2"){name="Sur mesure"; tot= 300;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(3);
    if(removeIndex != -1)this.orders.splice(removeIndex, 1);
    this.orders.push({id:3,name:name , item:tot});
    
    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(3);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
  }
  changed4(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="Ergonomie: Simple"; tot= 50;}
    if(value == "2"){name="Ergonomie: Normale"; tot= 100;}
    if(value == "3"){name="Ergonomie: Avancé"; tot= 250;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(4);
    if(removeIndex != -1)this.orders.splice(removeIndex, 1);
    this.orders.push({id:4,name:name , item:tot});
    
    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(4);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
  }
  changed5(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="1 à 7"; tot= 0;}
    if(value == "2"){name="7 à 15"; tot= 100;}
    if(value == "3"){name="15 à 30"; tot= 200;}
    if(value == "4"){name="30 à 100"; tot= 300;}
    if(value == "5"){name="+ 100"; tot= 400;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(5);
    if(removeIndex != -1)this.orders.splice(removeIndex, 1);
    this.orders.push({id:5,name:name , item:tot});
    
    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(5);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
  }
  changed6(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){
      name="Logo"; tot= 20;
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(6);
      if(removeIndex != -1)this.orders.splice(removeIndex, 1);
      this.orders.push({id:6,name:name , item:tot});
      
      
    }else{
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(6);
      this.orders.splice(removeIndex, 1);
    }
    let xx = 0;
      this.orders.forEach(function (v) {
        xx += v.item;
      }); 

      this.total = xx;
  }
  changed7(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){
      name="Référencement Naturel"; tot= 150;
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(7);
      if(removeIndex != -1)this.orders.splice(removeIndex, 1);
      this.orders.push({id:7,name:name , item:tot});
      
     
    }else{
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(7);
      this.orders.splice(removeIndex, 1);
    }
    let xx = 0;
      this.orders.forEach(function (v) {
        xx += v.item;
      }); 

      this.total = xx;

  }
  changed8(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){
      name="Hébergement"; tot= 100;
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(8);
      if(removeIndex != -1)this.orders.splice(removeIndex, 1);
      this.orders.push({id:8,name:name , item:tot});
      
     
    }else{
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(8);
      this.orders.splice(removeIndex, 1);
    }
    let xx = 0;
      this.orders.forEach(function (v) {
        xx += v.item;
      }); 

      this.total = xx;

  }
  changed9(value) {
    let name = "";
    let tot = 0;
    if(value == "1"){name="15 jours maximum"; tot= 200;}
    if(value == "2"){name="1 mois"; tot= 50;}
    if(value == "3"){name="2 mois"; tot= 0;}
    if(value == "4"){name="3 mois"; tot= 0;}
    if(value == "5"){name="+ 6 mois"; tot= 0;}

    var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(9);
    if(removeIndex != -1)this.orders.splice(removeIndex, 1);
    this.orders.push({id:9,name:name , item:tot});
    
    if(value == ""){
      var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(9);
      this.orders.splice(removeIndex, 1);      
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 

    this.total = xx;
  }

  changedc1(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(11);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:11,name:"fonctionnalité: Actualités" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(11);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }


  changedc2(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(12);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:12,name:"fonctionnalité: Recherche" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(12);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }


  changedc3(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(13);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:13,name:"fonctionnalité: Formulaire contact" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(13);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc4(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(14);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:14,name:"fonctionnalité: Galerie Photos" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(14);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc5(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(15);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:15,name:"fonctionnalité: Multi-langue" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(15);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc6(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(16);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:16,name:"fonctionnalité: Galerie Vidéos" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(16);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc7(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(17);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:17,name:"fonctionnalité: Commentaires" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(17);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc8(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(18);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:18,name:"fonctionnalité: Notifications" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(18);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc9(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(19);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:19,name:"fonctionnalité: Espace Client" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(19);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc10(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(20);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:20,name:"fonctionnalité: Espace Admin" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(20);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc11(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(21);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:21,name:"fonctionnalité: Forum" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(21);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }

  changedc12(value,e) {
    if(e.target.checked){ 
        var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(22);
        if(removeIndex != -1)this.orders.splice(removeIndex, 1);
        this.orders.push({id:22,name:"fonctionnalité: Import/Export" , item:parseInt(value)});
    }else{
       var removeIndex = this.orders.map(function(item) { return item.id; }).indexOf(22);
       this.orders.splice(removeIndex, 1);
    }

    let xx = 0;
    this.orders.forEach(function (v) {
      xx += v.item;
    }); 
    this.total = xx;
    
  }



  Continuer(){
    console.log('ccccss');
  }





}
