import { Component, OnInit } from '@angular/core';
import { DynamicFileAdminServiceService } from 'src/app/dynamic-file-service.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  random : number ;
  constructor(private dynamicScriptLoader: DynamicFileAdminServiceService) { }

  ngOnInit() {
     this.random = Math.floor(Math.random() * 4) + 1 ;
     this.loadScripts();
  }

  // js
  private loadScripts() {
    this.dynamicScriptLoader.load('c1').then(data => {
    }).catch(error => console.log(error));
  }


}
