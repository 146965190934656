
        <!-- Start Navbar Area -->
        <div class="navbar-area">
            <div class="aronix-responsive-nav">
                <div class="container">
                    <div class="aronix-responsive-menu">
                        <div class="logo">
                            <a href="/">
                                <img src="assets/img/logo.png" alt="logo">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="aronix-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" href="/">
                            <img src="assets/img/logo.png" alt="logo" style="height: 47px!important">
                        </a>

                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                <li class="nav-item"><a routerLink="/" class="nav-link" [ngClass]="{'active': router.url=='/'}">Accueil</a></li>
                                <li class="nav-item">
                                    <a routerLink="/services" class="nav-link" [ngClass]="{'active': router.url=='/services'}">Services</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/skills" class="nav-link" [ngClass]="{'active': router.url=='/skills'}">Compétences</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/projects" class="nav-link" [ngClass]="{'active': router.url=='/projects'}">Projets</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/contact" class="nav-link" [ngClass]="{'active': router.url=='/contact'}">Contact</a>
                                </li>
                            </ul>

                            <div class="others-options">
                                <div class="burger-menu" (click)="toggle_about()">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <!-- End Navbar Area -->

        <!-- Sidebar Modal -->
        <div class="sidebar-modal">
            <div class="sidebar-modal-inner">
                <div class="sidebar-about-area">
                    <div class="title">
                        <h2>À propos de nous</h2>
                        <p>Digi-Dev est une entreprise de services informatiques, spécialisée dans la conception, le design, le développement et la promotion d’applications mobiles et des sites web (site statique, site dynamique, site vitrine, site e-commerce, site mobile, application web, site communautaire, etc). Notre équipe assure aussi des services informatiques à la demande.</p>
                    </div>
                </div>

                <div class="sidebar-instagram-feed">
                    <h2>Projets</h2>

                    <ul>
                        <li><a href="javascript:void(0);">
                            <img src="assets/img/instagram-image/a1.jpg" alt="image">
                        </a></li>
                        <li><a href="javascript:void(0);">
                            <img src="assets/img/instagram-image/a2.png" alt="image">
                        </a></li>
                        <li><a href="javascript:void(0);">
                            <img src="assets/img/instagram-image/a3.png" alt="image">
                        </a></li>
                        <li><a href="javascript:void(0);">
                            <img src="assets/img/instagram-image/a4.jpg" alt="image">
                        </a></li>
                        
                    </ul>
                </div>

                <div class="sidebar-contact-area">
                    <div class="contact-info">
                        <div class="contact-info-content">
                            <h2>
                                <a href="tel:21693033093">+216 93 033 093</a>
                                <span>OU</span>
                                <a href="mailto:contact@digi-dev.net">contact@digi-dev.net</a>
                            </h2>
    
                            <ul class="social">
                                <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.facebook.com/digidev.net" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <span class="close-btn sidebar-modal-close-btn"><i class="flaticon-close"></i></span>
            </div>
        </div>
        <!-- End Sidebar Modal -->

        <script type="text/javascript">
            document.getElementsByClassName("sidebar-modal")[0].classList.toggle("active");
        </script>